import React from 'react';
import ReactDOM from 'react-dom';
import OnboardingPage from './Onboarding';

let styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "./index.css";
document.head.appendChild(styleLink);

ReactDOM.render(
  <OnboardingPage/>,
  document.getElementById('root')
);
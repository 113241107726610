import React from "react";
import axios from 'axios';
import { Container, Dimmer, Loader, Button, Form, Modal, Message } from "semantic-ui-react";
import { ReactComponent as KiveraLogo } from './images/logo-only.svg';
import { ReactComponent as OrganizationCreated } from './images/created.svg';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const orgNameRegex = /(^[a-zA-Z0-9_\- ]{1,50}$)/

class OnboardingPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            'loading': false,
            'complete': false,
            'errorModal': false,
            'errorModalRetry': false,
            'formError': false,
            'formLoading': false,
            'registerError': '',
            'registerErrorType': '',
            'customerIdentifier': '',
            'productCode': '',
            'orgName': '',
            'adminEmail': '',
            'agreement': false,
            'orgNameError': false,
            'adminEmailError': false,
            'agreementError': false,
            'formErrorMessage': '',
            'formErrorType': '',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        // this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    getSearchParams() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams;
    }

    registerToken() {
        this.setState({ 'formLoading': false })
        this.registerTokenState(true, false)
        let mgmtURL = new URL(`${process.env.REACT_APP_MGMT_ENDPOINT}/org/register`)
        mgmtURL.search = this.getSearchParams()
        axios.post(mgmtURL.toString())
            .then(res => {
                this.registerTokenState(false, false)
                this.setState({
                    'customerIdentifier': res.data.CustomerIdentifier,
                    'productCode': res.data.ProductCode
                })
                this.createOrganization()
            })
            .catch(error => {

                let err = ''
                let errType = ''
                if (error.response) {
                    err = error.response.data.error
                    errType = error.response.data.type.replace("Exception", "");
                    this.registerTokenState(false, true, true, err, errType)
                } else if (error.request) {
                    this.registerTokenState(false, true, true, `Unable to connect to to ${mgmtURL}.`, 'ConnectionError')
                } else {
                    this.registerTokenState(false, true, true, 'Unknown error.', 'UnknownError')
                }
            })
    }

    registerTokenState(loading, errorModal, retry = false, error = '', errorType = '') {
        this.setState({
            'loading': loading,
            'errorModal': errorModal,
            'errorModalRetry': retry,
            'registerError': error,
            'registerErrorType': errorType
        })
    }

    createOrganization() {
        let mgmtURL = new URL(`${process.env.REACT_APP_MGMT_ENDPOINT}/org`)
        axios.post(mgmtURL.toString(),
            {
                'org_name': this.state.orgName,
                'admin_email': this.state.adminEmail,
                'customer_id': this.state.customerIdentifier,
                'product_code': this.state.productCode,
                'agreement': this.state.agreement,
            })
            .then(this.setState({ 'complete': true }))
            .catch(error => {
                let err = ''
                let errType = ''
                if (error.response) {
                    err = error.response.data.error
                    errType = error.response.data.type.replace("Exception", "");
                } else if (error.request) {
                    err = `Unable to connect to to ${mgmtURL}.`
                    errType = 'ConnectionError'
                } else {
                    err = 'Unknown error.'
                    errType = 'UnknownError'
                }
                this.setState({
                    'formError': true,
                    'formErrorType': errType,
                    'formErrorMessage': err,
                    'formLoading': false,
                    'complete': false
                })
            })
    }

    // handleCheckboxChange() {
    //     this.setState({
    //         'agreement': !this.state.agreement,
    //         'agreementError': false
    //     })
    // }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ [`${event.target.name}Error`]: false })
    }

    handleSubmitState(state, stateKey) {
        let error = !state ? true : false;
        this.setState({ [stateKey]: error })
        return error
    }

    formState(error, type = '', msg = '') {
        this.setState({
            'formError': error,
            'formErrorType': type,
            'formErrorMessage': msg,
            'formLoading': false,
            'complete': false
        })
    }

    handleSubmit(event) {
        this.setState({ 'formLoading': true })

        let error = false;
        const { orgName, adminEmail } = this.state

        var errType = 'Missing Fields'
        var errMsg = 'All fields are required.'

        error = this.handleSubmitState(orgName, 'orgNameError') ? true : error
        error = this.handleSubmitState(adminEmail, 'adminEmailError') ? true : error
        // error = this.handleSubmitState(agreement, 'agreementError') ? true : error

        if (error) {
            this.formState(error, errType, errMsg)
            return
        }

        if (! orgNameRegex.test(orgName)) {
            errType = 'Invalid Organization Name'
            errMsg = 'Can only contain alphanumeric characters, hyphens, underscores and spaces, and be a maximum of 50 characters long.'
            this.formState(true, errType, errMsg)
            return
        }
        if (! emailRegex.test(adminEmail)) {
            errType = 'Invalid Email'
            errMsg = 'Provided email address is invalid.'
            this.formState(true, errType, errMsg)
            return
        }

        this.formState(false)
        this.registerToken()
        event.preventDefault();
    }

    render() {

        if (!this.state.complete) {
            var modalContent
            var nextAction
            if (this.state.registerError.includes("already assigned to an organization")) {
                modalContent = <span>Login to start using Kivera.</span>
                nextAction = <Button color='black' href={process.env.REACT_APP_KIVERA_CONSOLE}> Login to Kivera </Button>
            } else {
                modalContent = <span>Try subscribing again or contact <a href="mailto: support@kivera.io">Kivera Support</a></span>
                nextAction = <Button color='black' href={process.env.REACT_APP_MARKETPLACE_URL}> Return to AWS Marketplace </Button>
            }
            return (
                <Container className={"mainContainer"}>
                    <Container className={"logoContainer"}>
                        <div className="row" style={{ textAlign: 'center' }}>
                            <KiveraLogo style={{ width: '200px' }} />
                        </div>
                    </Container>
                    <Container className={"formContainer"}>
                        <div className="row">
                            <Dimmer active={this.state.loading} >
                                <Loader active={this.state.loading} inline='centered'>Verifying token</Loader>
                            </Dimmer>

                            <Modal size='small' open={this.state.errorModal}>
                                <Modal.Header>
                                    <h1><i class="big red times circle icon"></i> {this.state.registerErrorType}</h1>
                                </Modal.Header>
                                <Modal.Content>
                                    <p>{this.state.registerError}. {modalContent}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    {this.state.errorModalRetry && nextAction}
                                </Modal.Actions>
                            </Modal>

                            <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                                <Form.Input label="Organization Name"
                                    name="orgName"
                                    placeholder='A name for your organization'
                                    value={this.state.orgName}
                                    error={this.state.orgNameError}
                                    onChange={this.handleInputChange} />
                                <Form.Input label='Admin Email'
                                    name="adminEmail"
                                    placeholder="Admin email address"
                                    value={this.state.adminEmail}
                                    error={this.state.adminEmailError}
                                    onChange={this.handleInputChange} />
                                <div className="row" style={{ textAlign: 'center' }}>
                                    <br />
                                    <Button className="kButton" type="submit">Create Kivera Organization</Button>
                                    <br /><br />
                                </div>

                            </Form>
                            <Message hidden={!this.state.formError}
                                error
                                header={this.state.formErrorType}
                                content={this.state.formErrorMessage}
                            />
                        </div>
                    </Container>
                </Container>
            );
        } else {
            return (
                <Container className={"mainContainer"}>
                    <div className="row">
                        <OrganizationCreated style={{ paddingTop: '100px', maxWidth: '700px' }} />
                        <div className="col-md-7" style={{ textAlign: 'center' }}>
                            <h1 style={{ paddingTop: '50px' }} >Organization Created Successfully <i class="green check circle icon"></i></h1>
                            <br />
                            <p><i class="info circle icon"></i> Check your email to set your password before logging in.</p>
                            <br />
                            <Button href={process.env.REACT_APP_KIVERA_CONSOLE} className="kButton">Login to Kivera Console</Button>
                        </div>
                        <div className="col-md-7" style={{ marginTop: '50px', textAlign: 'center' }}>
                            <p style={{ fontSize: "14px", color: "#888" }}>For any issues, contact <a href="mailto:support@kivera.io">Kivera Support</a>.
                            </p>
                        </div>
                    </div>



                </Container>
            );
        }
    }
}

export default OnboardingPage;
